import triggerStyles from 'bundle-text:./cf.css'
import demoStyles from 'bundle-text:./cf-demo.css'
import widgetStyles from 'bundle-text:./widget.css'

console.log('ContactFormProDemoMode', window.ContactFormProDemoMode)

if (window.ContactFormProDemoMode) {
  const style = document.createElement('style')
  style.textContent = demoStyles
  console.log('demo styles', style)
  document.head.appendChild(style)
}

const widgetHtml = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>CF Widget</title>
        <style>${widgetStyles}</style>
        <link rel="preload" as="style" href="https://embed.contactform.pro/widget.css">
        <link rel="stylesheet" href="https://embed.contactform.pro/widget.css">
      </head>
      <body>
        <div id="CF" style="height: 100%; opacity: 0;"></div>
      </body>
    </html>
  `

const CFKey = 'CF_PRO_APP_'
window[CFKey + 'IS_OPEN'] = false
let ignoreClick = false
let baseUrl = 'https://api.contactform.pro'
if (process.env.NODE_ENV !== 'production') {
  baseUrl = 'http://localhost:3001'
}

function open (settings = {}, composeData = {}) {
  if (ignoreClick) {
    return
  } else {
    ignoreClick = true
    setTimeout(() => {
      ignoreClick = false
    }, 600)
  }

  console.log('Mounting state: ', window[CFKey + 'IS_MOUNTING'])

  document.querySelector('.cf-container').classList.remove('cf-container-load-transition')

  if (window[CFKey + 'IS_OPEN']) {
    document.querySelector('.cf-blanket').classList.add('cf-blanket-inactive-transition')
    window[CFKey + 'IS_OPEN'] = false
    document.querySelector('.cf-container').classList.remove('cf-container-bounce')
    document.querySelector('.cf-container').classList.add('cf-container-bounce-out')
    document.querySelector('.cf-blanket').classList.add('cf-blanket-inactive')
    document.querySelector('.cf-blanket').classList.remove('cf-blanket-active')
    document.querySelector('.cf-trigger').classList.remove('cf-trigger-active')
    document.querySelector('.cf-widget').remove()
    return
  }

  if (Object.keys(settings).length === 0) {
    throw new Error('Missing settings')
  }

  document.querySelector('.cf-container').classList.remove('cf-container-bounce-out')
  document.querySelector('.cf-container').classList.add('cf-container-bounce')
  document.querySelector('.cf-blanket').classList.add('cf-blanket-active')
  document.querySelector('.cf-blanket').classList.remove('cf-blanket-inactive')
  document.querySelector('.cf-blanket').classList.remove('cf-blanket-inactive-transition')
  document.querySelector('.cf-trigger').classList.add('cf-trigger-active')

  const iframe = document.createElement('iframe')
  iframe.className = 'cf-widget'

  if (process.env.NODE_ENV !== 'production') {
    let iframeSrc = `/widget.html?params=${encodeURIComponent(JSON.stringify(settings))}`

    if (Object.keys(composeData).length > 0) {
      iframeSrc = iframeSrc + `&compose=${encodeURIComponent(JSON.stringify(composeData))}`
    }

    iframe.src = iframeSrc
    iframe.addEventListener('load', () => {
      hideEntice()
      iframe.classList.add('cf-widget-active')
    })
  }

  if (process.env.NODE_ENV === 'production') {
    iframe.addEventListener('load', () => {
      const docOld = iframe.contentWindow.document
      const docNew = new DOMParser().parseFromString(widgetHtml, 'text/html')
      docOld.insertBefore(docNew.doctype, docOld.firstChild)
      docOld.replaceChild(docNew.documentElement, docOld.documentElement)

      const settingsScript = document.createElement('script')
      settingsScript.innerHTML = `window.settings = ${JSON.stringify(settings)}`
      docOld.body.append(settingsScript)

      if (Object.keys(composeData).length > 0) {
        const composeScript = document.createElement('script')
        composeScript.innerHTML = `window.compose = ${JSON.stringify(composeData)}`
        docOld.body.append(composeScript)
      }

      const script = document.createElement('script')
      script.addEventListener('load', () => {
        hideEntice()
        iframe.classList.add('cf-widget-active')
      })
      script.src = 'https://embed.contactform.pro/widget.js'
      docOld.body.append(script)
    })
  }
  document.body.appendChild(iframe)
  window[CFKey + 'IS_OPEN'] = true
}

if (!window[CFKey + 'IS_MOUNTING']) {
  window[CFKey + 'IS_MOUNTING'] = true
  window.addEventListener('load', async function () {
    const cfScript = document.querySelector('[data-cf]')
    let tenant = cfScript && cfScript.getAttribute('data-cf')
    let settings
    if (tenant) {
      settings = await getSettings(tenant)
    } else {
      tenant = 'demo'
      settings = {
        color: '#1A8B85',
        colorInvert: false,
        customAvatar: false,
        enabled: true,
        icon: 1,
        closeIcon: 1,
        name: tenant,
        blanket: false,
        iconShape: 0
      }
    }

    if (settings && settings.enabled && settings.enabled === true) {
      window.addEventListener('message', function (message) {
        if (message && message.data) {
          switch (message.data) {
            case `${CFKey}PREVIEW`:
              settings.preview = message.origin
              break
            case `${CFKey}OPEN`:
              open(settings)
              break
            default:
              break
          }
        }
      })

      const openIconUrl = `https://assets.contactform.pro/icons/open/${settings.icon}/icon.svg`
      const closeIconUrl = `https://assets.contactform.pro/icons/close/${settings.closeIcon}/icon.svg`

      settings.tenant = tenant
      const style = document.createElement('style')
      style.textContent = triggerStyles
      const triggerActiveCss = document.createTextNode(`.cf-trigger-active { background-image: url('${closeIconUrl}') !important; }`)
      const enticeHoverCss = document.createTextNode(`.cf-entice .cf-entice-action:hover { background: ${settings.color}; }`)
      const enticeHoverInvertCss = document.createTextNode(`.cf-entice .cf-entice-action:hover { color: ${settings.colorInvert ? 'black' : 'white'}; }`)
      style.appendChild(triggerActiveCss)
      style.appendChild(enticeHoverCss)
      style.appendChild(enticeHoverInvertCss)
      document.head.appendChild(style)

      const container = document.createElement('div')
      container.className = 'cf-container cf-container-load-transition'
      let iconRadius = '100%'
      switch (settings.iconShape) {
        case 0:
          break
        case 1:
          iconRadius = '20%'
      }
      container.style = `background-color: ${settings.color}; border-radius: ${iconRadius}; border: solid 0px ${settings.color};`

      const trigger = document.createElement('div')
      trigger.className = 'cf-trigger'
      const iconStyles = []
      if (settings.colorInvert) {
        iconStyles.push('filter: invert(100%);')
      }

      iconStyles.push(`background-image: url('${openIconUrl}');`)
      trigger.style = iconStyles.join(' ')
      trigger.onclick = () => open(settings)
      trigger.innerHTML = '<img alt="" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="/>'
      container.appendChild(trigger)

      const blanket = document.createElement('div')
      blanket.className = 'cf-blanket'
      blanket.classList.add('cf-blanket-inactive')
      if (!settings.blanket) {
        blanket.style = 'display: none;'
      }
      document.body.appendChild(blanket)

      const entice = document.createElement('div')
      entice.className = 'cf-entice'
      document.body.appendChild(entice)

      const preloadOpenIcon = new Image()
      preloadOpenIcon.src = openIconUrl
      preloadOpenIcon.onload = function () {
        document.body.appendChild(container)
        setupEntice(settings)
      }

      const preloadCloseIcon = new Image()
      preloadCloseIcon.src = closeIconUrl

      window.ContactFormPro = {
        open: function () {
          open(settings)
        }
      }
    }
  })
} else {
  console.log('Already mounting - possible collision')
}

async function getSettings (tenant) {
  try {
    return await window.fetch(`${baseUrl}/v1/widget/settings`, {
      headers: {
        'cform-tenant': tenant
      }
    }).then(async (response) => {
      if (response.status === 200) {
        return await response.json().then(json => json)
      }
    })
  } catch (e) {
    console.log(e, tenant)
  }
}

async function getEntice (tenant) {
  try {
    return await window.fetch(`${baseUrl}/v1/widget/entice`, {
      headers: {
        'cform-tenant': tenant
      }
    }).then(async (response) => {
      if (response.status === 200) {
        return await response.json().then(json => json)
      }
    })
  } catch (e) {
    console.log(e, tenant)
  }
}

async function setupEntice (settings) {
  const { tenant } = settings
  let isDismissed
  try {
    isDismissed = localStorage && localStorage.getItem(`${CFKey}ENTICE_DISMISSED_${tenant}`)
    if (!localStorage) {
      isDismissed = 0
    }
  } catch {
    isDismissed = 0
  }

  const oneDay = 86400000

  const condition = isDismissed && (Date.now() - (oneDay * 14) < isDismissed)
  if (settings.entice && !condition) {
    try {
      const enticeTypes = await getEntice(tenant)
      initiateEntice(settings, enticeTypes)
    } catch (e) {
      console.log('Error fetching entice types')
    }
  }
}

async function initiateEntice (settings, enticeTypes) {
  const enticeClose = document.createElement('div')
  enticeClose.className = 'cf-entice-close'
  enticeClose.onclick = () => hideEntice(settings)
  enticeClose.innerHTML = '<img alt="" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="/>'
  if (settings.colorInvert) {
    enticeClose.style = 'filter: invert(100%)'
  }

  const container = document.getElementsByClassName('cf-entice')[0]
  if (!container) {
    return
  }

  await decorateEntice(enticeTypes)

  const enticeCloseContainer = document.createElement('div')
  enticeCloseContainer.className = 'cf-entice-close-container'
  enticeCloseContainer.style = `background-color:${settings.color}`
  enticeCloseContainer.appendChild(enticeClose)
  setTimeout(() => container.appendChild(enticeCloseContainer), 1000)
}

async function decorateEntice (enticeTypes) {
  const container = document.getElementsByClassName('cf-entice')[0]

  for (let i = 0; i < enticeTypes.length; i++) {
    switch (enticeTypes[i].parent) {
      case 0: {
        const enticeMessage = document.createElement('div')
        enticeMessage.className = 'cf-entice-message'
        enticeMessage.innerHTML = enticeTypes[i].value
        enticeMessage.onclick = () => window.ContactFormPro.open()
        container.appendChild(enticeMessage)
        break
      }
      case 1: {
        const urlLabelPair = enticeTypes[i].value.split(',')
        const url = window.atob(urlLabelPair[0])
        const label = window.atob(urlLabelPair[1])
        const enticeAction = document.createElement('a')
        enticeAction.href = url
        enticeAction.target = '_blank'
        enticeAction.className = 'cf-entice-action'
        enticeAction.innerHTML = label
        container.appendChild(enticeAction)
        break
      }
    }
  }
}

async function hideEntice (settings) {
  if (settings && settings.tenant && localStorage) {
    localStorage.setItem(`${CFKey}ENTICE_DISMISSED_${settings.tenant}`, Date.now())
  }
  const container = document.getElementsByClassName('cf-entice')[0]
  if (container) {
    container.style = 'display: none;'
  }
}
